import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import Cards from '../components/Cards'

// eslint-disable-next-line
export const ContactPageTemplate = ({
  lang,
  text,
  address,
  mails,
  phone,
  map,
}) => {
  let title
  let address_title
  let phone_title

  if (lang === 'fr') {
    title = 'Contact'
    address_title = 'Adresse'
    phone_title = 'Téléphone'
  } else {
    title = 'Kontakt'
    address_title = 'Adresse'
    phone_title = 'Telefon'
  }

  const coordinates = JSON.parse(map).coordinates
  const position = [coordinates[1], coordinates[0]]
  const emails = []

  for (let i = 0; i < mails.length; i++) {
    emails.push(
      <span key={`mail-${i}`} className="d-block">
        {mails[i].name}: <a href={`mailto:${mails[i].mail}`}>{mails[i].mail}</a>
      </span>,
    )
  }

  const cards = []

  cards.push({
    title: address_title,
    description: (
      <>
        <span className="d-block">{address.street}</span>
        <span className="d-block">
          {address.zip} {address.city}
        </span>
        <span className="d-block">{address.country}</span>
      </>
    ),
  })

  cards.push({
    title: 'E-Mail',
    description: <>{emails}</>,
  })

  cards.push({
    title: phone_title,
    description: phone,
  })

  return (
    <>
      <div className="container content-container">
        <h2>{title}</h2>
        <div className="row">
          <p>{text}</p>
        </div>
        <Cards cards={cards} className="col-lg-4" />
      </div>
      <div className="map-container">
        <MapContainer
          className="map map-container"
          dragging={false}
          center={position}
          zoom={16}
          zoomControl={false}
          scrollWheelZoom={false}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <Marker position={position}></Marker>
        </MapContainer>
      </div>
    </>
  )
}

ContactPageTemplate.propTypes = {
  lang: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  address: PropTypes.shape({
    city: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    zip: PropTypes.number.isRequired,
  }).isRequired,
  mails: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      mail: PropTypes.string.isRequired,
    }),
  ),
  phone: PropTypes.string.isRequired,
  map: PropTypes.string.isRequired,
}

const ContactPage = ({ data, pageContext }) => {
  const { markdownRemark } = data
  const { text, address, mails, phone, map } = markdownRemark.frontmatter
  const { lang } = pageContext

  return (
    <Layout pageContext={pageContext} className="flex-main">
      <ContactPageTemplate
        lang={lang}
        text={text}
        address={address}
        mails={mails}
        phone={phone}
        map={map}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        text: PropTypes.string.isRequired,
        address: PropTypes.shape({
          city: PropTypes.string.isRequired,
          company: PropTypes.string.isRequired,
          country: PropTypes.string.isRequired,
          street: PropTypes.string.isRequired,
          zip: PropTypes.number.isRequired,
        }).isRequired,
        mails: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            mail: PropTypes.string.isRequired,
          }),
        ),
        phone: PropTypes.string.isRequired,
        map: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }),
  pageContext: PropTypes.object.isRequired,
}

export default ContactPage

export const contactPageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        text
        address {
          city
          company
          country
          mail
          street
          zip
        }
        mails {
          name
          mail
        }
        phone
        map
      }
    }
  }
`
